@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

.section1 {
  background-color: #232931 !important;
  color: white !important;
}

body {
  font-family: "Merriweakther", sans-serif;
}

.theme-color {
  color: #605ac4;
}

.c-blue {
  color: #92c5d4;
}

.theme-bg-color {
  background: linear-gradient(135deg, #5f53a0, #c260a2);
}

.theme-bg-secondary {
  background: #edf2f6;
}
.theme-bg-dark {
  background: #48556a;
}
.navbar-top {
  backdrop-filter: blur(20px);
  /* background: linear-gradient(150deg, #5f53a0, #c260a2); */
  /* position: absolute; */
  height: 80px;
  width: 100%;
}

.padding-top-100 {
  padding-top: 100px;
}

.nodecor {
  text-decoration: none;
}

.hero-img {
  height: 350px;
  width: auto;
}

.member-photo {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  object-fit: contain;
}

/* .left-div,
.right-div {
  width: 48%;
  position: relative;
}

.left-div::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 100%;
  border-right: 1px dotted #c260a2;
}


@media (max-width: 768px) {
  .left-div::after {
    display: none;
  }
} */
